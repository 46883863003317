/* #navbar{
  width: 98.5vw;
  height: 80px;
  position: fixed;
  background-color: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  
}
.imagebox{
  top: 80px;
  height: 100vh;
  width: 98.5vw;
  position: relative;
}
.image{
  height: 100%;
  width: 100%;
  background-color: red;
  display: block;
}
.des{
  position: absolute;
  right: 0;
  bottom: 0;
}



@media screen and (max-width: 600px) {
  .des{
    position: absolute;
    left: 0;
    bottom: 0;
  }
} */

*{
  margin: 0;
  padding: 0;
}

html{
  scroll-behavior: smooth;
}

body{
  font-size: 100%;
  font-family: sans-serif;
}

div, section, span, ul, li, a, header{
  box-sizing: border-box;
}

/*css reset*/

header{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 50px;
  align-items: center;
  position: fixed;
  z-index: 999;
  background-color: #fff;
}

#menu li{
  list-style: none;
  display: inline-block;
  margin: 20px;
}

#menu li a{
  color: #000000;
  text-decoration: none;
  font-size: 20px;
}

section{
  width: 100%;
  float: left;
  height: 100vh;
  position: relative;
}

#home{
 background-color: #ebebeb;
 overflow: hidden;
}

#about{
   background-color: #72ad77;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 2vh;
   flex-direction: column;
}

#product{
   background-color: #c4bb6e;
   display: flex;
   flex-wrap: wrap;
   padding: 5%;
   height: auto;
   
}

#pricing{
   background-color: #3C2C3E;
}

#contact{
   background-color: #B52B65;
}

#contact .contact{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
header a, header img{
  height: 50px;
  width: 100px;
  display: inline-block;
}
.product{
  width: 32%;
  height: auto;
  box-sizing: border-box;
  border: 1px solid #ff9100;
  margin: 2px;
}
.product img{
  width: 88px;
  height: auto;


}
.product .productimage{
  justify-content: center;
  align-items: center;
  display: flex;
  height: 20vh;
}
h3{
  text-align: center;
  margin: 20px 10px;
  padding: 20px;
  background-color: #877a0f;
}
.aboutProduct{
  width: auto;
  text-align: left;
  padding: 5% 10%;


}
.aboutProduct li{
  list-style-type: none;
  text-align: center;
  font-weight: bold;
  font-style: italic;
  color: #3f3f3f;
}

#contact .contact img{
width: 40%;
}

#contact .contact h1 {
 display: block;
 /* width: 60%; */
  }
span{
  /* width: 50%; */
  display: block;
}
.contact{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#backgroundimg{
  width: 100%;
 
}
.companyimage{
  height: 50vh ;
  width: 50vh ;
  border: 3px solid rgb(83, 92, 5);
  display: block;
  border-radius: 50vh;
  margin-bottom: 5vh;
  content: "";
  text-align: justify;

 
}
button{
  border: none;
  background-color: rgb(190, 111, 6);
  margin-top: 20px;
  padding: 15px 20px;
  border: 1px solid black;
  color: #B52B65;
  border-radius: 10px;
}
button a{
  text-decoration: none;
}
#ourproduct{
  text-align: center;
  line-height: 7vh;
}




/* for details page */
#home .detail{
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  /* padding: 0vh 10vh; */
}
#home .detail .right, #home .detail .left{
  display: flex;
  height: 100vh;
  width: 50vw;
  justify-content: center;
  align-items: center;
  /* background-color: #72ad77; */
}
 #home .detail .left{

  background-color: #c4bb6e;
}


#home .detail .right{
  display: flex;
justify-content: center;
flex-direction: column;
}

#home .detail .left img{
  height: 70vh;
  margin-top: 20vh;

}
#home .detail .right h1{
  margin-bottom: 3vh;
}







@media only screen and (max-width: 900px) {
  #menu li {
    margin: 0px;
}
#menu li a {
  font-size: 10px;
}
header a {
  height: auto;
  width: auto;
  display: inline-block;
  font-weight: bold;
}
header a, header img {
  height: 20px;
  width: 50px;
  display: inline-block;
}
#home{
  height: auto;
}
.product {
  width: 46%;
  height: auto;
  box-sizing: border-box;
  border: 2px solid #ff9100;
  margin: 2%;
  overflow: hidden;
}
#product{
  height: auto;
  padding: 30px 0;
}
.aboutProduct {
  width: auto;
  text-align: left;
  padding: 5% 5%;
  background-color: #877a0f;
  margin-top: 20px;
  min-height: 50px;
}
.aboutProduct li{
  font-size: 2vw;
  list-style-type: none;
  text-align: center;
  color: #ebebeb;
}
#contact .contact {
 
  width: 90%;
}
.productimage{
  height: 20vh !important;
  margin-top: 10px;

}
.companyimage {
  height: 30vh;
  width: 30vh;
}
.product img {
  width: 60% !important;
  height: auto;
}
.product h3 {
    text-align: center;
    margin:  0;
    padding: 0;
    background-color: transparent;
    line-height: 50px;
}
#about h1{
  text-align: justify;
}
/* for details page */
#home .detail{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0vh 0vw 10vh 0;
}
 #home .detail .left{
 
  height: 70vh;
  width: 100vw;
  background-color: #c4bb6e;
  padding: o 10vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home .detail .left img {
    width: 80vw;
    height: 50vh;
    margin: 0;
}
#home .detail .right h1{
  text-align: center;
  margin: 3vh;
}
#home .detail .right{
  display: flex;
  width: 100vw;
  height: auto;
  /* padding: 10vh 10vw; */
  justify-content: center;
  align-items: center;

}





}




